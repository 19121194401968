import { useState, useEffect, useCallback } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useTickets = ({
  typeSearchParam,
  searchParam,
  pageNumber,
  status,
  date,
  showAll,
  queueIds,
  withUnreadMessages,
  tags,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [count, setCount] = useState(0);
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const { data } = await api.get("/settings/public");
        setSettings(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSettings();
  }, [setSettings]);

  const getSettingValue = useCallback(key => {
      if (settings.some(s => s.key === key)) {
        const { value } = settings.find(s => s.key === key);
        return value;
      }

      return "";
    }, [settings]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTickets = async () => {
        try {
          const { data } = await api.get("/tickets", {
            params: {
              typeSearchParam,
              searchParam,
              pageNumber,
              status,
              date,
              showAll,
              queueIds,
              withUnreadMessages,
              tags,
            },
          })
          setTickets(data.tickets)

          let horasFecharAutomaticamente = getSettingValue("hoursCloseTicketsAuto");

          let minutosRedirecionarClientesPendentes = getSettingValue("minutesRedirectClientPending")

          let dataLimiteRedirect = new Date()
          dataLimiteRedirect.setMinutes(
            dataLimiteRedirect.getMinutes() -
              Number(minutosRedirecionarClientesPendentes)
          )

          if (
            status === "open" &&
            horasFecharAutomaticamente &&
            horasFecharAutomaticamente !== "" &&
            horasFecharAutomaticamente !== "0" &&
            Number(horasFecharAutomaticamente) > 0
          ) {
            let dataLimiteFechar = new Date()
            dataLimiteFechar.setHours(
              dataLimiteFechar.getHours() - Number(horasFecharAutomaticamente)
            )

            data.tickets.forEach((ticket) => {
              if (ticket.status !== "closed") {
                let dataUltimaInteracaoChamado = new Date(ticket.updatedAt)
                if (dataUltimaInteracaoChamado < dataLimiteFechar)
                  closeTicket(ticket)
              }

              if (ticket.status === "pending" && ticket.queueId === null) {
                //console.log("ticket " + ticket.id + " is pending")
                let dataPrimeiraInteracaoChamado = new Date(ticket.createdAt)
                //console.log("dataUltimaInteracao")
                //console.log(dataPrimeiraInteracaoChamado)
                //console.log("dataLimiteRedirect")
                //console.log(dataLimiteRedirect)
                //console.log("dataPrimeiraInteracaoChamado < dataLimiteRedirect = " + (dataPrimeiraInteracaoChamado < dataLimiteRedirect ? "S" : "N"))

                if (dataPrimeiraInteracaoChamado < dataLimiteRedirect)
                  redirectTicketPending(ticket)
              }
            })
          }

          //console.log("data.tickets.some((x) => x.status === 'pending')")
          //console.log(data.tickets.some((x) => x.status === "pending"))

          const filterRule = (el) => el.status === "pending"

          if (data.tickets.some(filterRule)) {
            data.tickets.filter(filterRule).forEach((ticket) => {
              if (ticket.queueId === null) {
                //console.log("ticket " + ticket.id + " is pending")
                let dataPrimeiraInteracaoChamado = new Date(ticket.createdAt)
                //console.log("dataUltimaInteracao")
                //console.log(dataPrimeiraInteracaoChamado)
                //console.log("dataLimiteRedirect")
                //console.log(dataLimiteRedirect)
                //console.log("dataPrimeiraInteracaoChamado < dataLimiteRedirect = " + (dataPrimeiraInteracaoChamado < dataLimiteRedirect ? "S" : "N"))

                if (dataPrimeiraInteracaoChamado < dataLimiteRedirect)
                  redirectTicketPending(ticket)
              }
            })
          }

          setHasMore(data.hasMore)
          setCount(data.count)
          setLoading(false)
        } catch (err) {
          console.log(err)
          setLoading(false)
          toastError(err)
        }
      }

      const closeTicket = async (ticket) => {
        await api.put(`/tickets/${ticket.id}`, {
          status: "closed",
          userId: ticket.userId || null,
        })
      }

      /*const fetchPendingTickets = async () => {
                //console.log("fetchPendingTickets")
                try {
                    const { data } = await api.get("/tickets", {
                        params: {
                            status: 'pending',
                            queueIds: null,
                            showAll: 'true',
                        },
                    })

                    //console.log(data)

                    let minutosRedirecionarClientesPendentes = getSettingValue("minutesRedirectClientPending")

                    let dataLimite = new Date()
                    dataLimite.setMinutes(dataLimite.getMinutes() - Number(minutosRedirecionarClientesPendentes))

                    data.tickets.forEach(ticket => {
                        if (ticket.queueId === null) {
                            //console.log("ticket " + ticket.id + " is pending")
                            let dataPrimeiraInteracaoChamado = new Date(ticket.createdAt)
                            //console.log("dataUltimaInteracao")
                            //console.log(dataPrimeiraInteracaoChamado)
                            //console.log("dataLimite")
                            //console.log(dataLimite)
                            //console.log("dataPrimeiraInteracaoChamado < dataLimite = " + (dataPrimeiraInteracaoChamado < dataLimite ? "S" : "N"))

                            if (dataPrimeiraInteracaoChamado < dataLimite)
                                redirectTicketPending(ticket)
                        }
                    })
                } catch (err) {
                    toastError(err)
                }
            }*/

      const redirectTicketPending = async (ticket) => {
        let departamentoRedirecionarClientesPendentes = getSettingValue("queueRedirectClientPending")

        await api.put(`/tickets/${ticket.id}`, {
          //status: "pending",
          queueId: departamentoRedirecionarClientesPendentes,
        })
      }

      fetchTickets()
      //fetchPendingTickets()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [
    getSettingValue,
    typeSearchParam,
    searchParam,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    withUnreadMessages,
    tags,
    settings
  ])

  return { tickets, loading, hasMore, count };
};

export default useTickets;